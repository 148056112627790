import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import clientInformation from '../login/ClientInfo';
import Alerts from '../helpers/Alerts';
import { IsValidToken, CheckCustomerData } from "../../services/AuthenticationService";
import { GetQuickerDirectZelle, QuickerInitiateTransaction, EWalletDeposit } from "../../services/DepositService";
import Loading from '../helpers/Loader';
import BeatLoader from "react-spinners/BeatLoader"
import { onlyNumbers, BonusString } from '../helpers/common';
import Bonus from '../Bonus';
import { MissingAccountInfo } from "../deposit/MissingAccountInfo.jsx";
import { isMobile } from 'react-device-detect';

export const ZelleMethods = () => {
    const { id } = useParams();
    const clientInfo = clientInformation();
    let INSTANCEID = clientInfo.InstanceID;
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const [isLoading, setIsLoading] = useState(true);
    const [isValidate, setIsValidate] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();
    const [arrayZelleDeposits, setZelleDeposits] = useState(JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID == id; })[0]);
    const [arrayDirectZelle, setDirectZelle] = useState([]);
    const [arrayInitiateTransaction, setInitiateTransaction] = useState([]);
    const [showInstructions, setshowInstructions] = useState(true);
    const [showResponse, setshowResponse] = useState(false);
    const [disableControl, setDisableControl] = useState(false);

    useEffect(() => {
               
        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            await doCheckCustomerData();
            if (id === 'QuickerZelle') {
                await QuickerDirectZelle();
            }
            else {
                setIsLoading(false);
            }
        }

        ).catch(function (error) {
            navigate('/expired/');
        })

        if (arrayZelleDeposits == undefined) {
            navigate('/deposits/');
        }
        

    }, []);

    function handleResponseFromComponent(response){
        setIsValidate(response);
    }

    async function doCheckCustomerData() {
        await CheckCustomerData(localStorage.getItem('Token'),'zelle').then(async function (response) {
            if (!response.Authentication.AllComplete) {
                setIsValidate(true);
            }
            else {
                setIsValidate(false);
            }
        })
    };

    async function QuickerDirectZelle() {
            await GetQuickerDirectZelle(localStorage.getItem('Token'), arrayZelleDeposits.ProcessorID).then(async function (response) {
                
            const filtered = response.Authentication.BankAccounts.map(item => {
                const {
                    AccountName,
                    AccountNumber,
                    AccountType,
                    BankAccountID,
                    BankName,
                    RoutingNumber,
                    RoutingPaperCheck,
                    RoutingWire
                } = item;{
                    return {
                        AccountName,
                        AccountNumber,
                        AccountType,
                        BankAccountID,
                        BankName,
                        RoutingNumber,
                        RoutingPaperCheck,
                        RoutingWire
                    };
                }
            });
            await setDirectZelle(filtered[0]);
            setIsLoading(false);
        })
            .catch(function (error) {
                setIsLoading(false);
                Alerts.ShowAlert('Error', 'Error bank accounts', 'error');
            })

    }

    async function InitiateTransaction(Amount) {
        if (arrayDirectZelle != undefined) {
            await QuickerInitiateTransaction(localStorage.getItem('Token'), arrayZelleDeposits.ProcessorID, Amount, arrayDirectZelle.BankAccountID, BonusString(JSON.parse(localStorage.getItem('ArrayBonus')))).then(async function (response) {
                await setInitiateTransaction(response.Authentication);
                if (response.Authentication.status === 'REJECTED') {
                    setIsLoading(false);
                    Alerts.ShowAlert('Error', response.Authentication.HtmlResponse, 'error');
                } else {
                    setshowResponse(true);
                    setshowInstructions(false);
                }
            })
                .catch(function (error) {
                    setIsLoading(false);
                    Alerts.ShowAlert('Error', 'Error initiate transaction', 'error');
                })
        }
        else {
            setIsLoading(false);
            Alerts.ShowAlert('Error', 'No Zelle accounts available from provider.', 'error');
        }
    }

    async function InitiateTransactionVoucher(Amount) {
       
            var Data =
            {
                "Token": localStorage.getItem('Token'),
                "eWalletAccount": '',
                "eWalletPassword": '',
                "ProcessorName": arrayZelleDeposits.DepositID,
                "InstanceID": INSTANCEID,
                "Amount": Amount,
                "CurrencyCode": "USD",
                "returnURL": "",
                "statusURL": "",
                "TransactionID": "-1",
                "IPv4Address": clientInfo.IPAddress,
                "BonusList": BonusString(JSON.parse(localStorage.getItem('ArrayBonus')))
            };

        await EWalletDeposit(Data).then(function (response) {
            //setArrayEwallet(response.Authentication);

            if (response.Authentication.ErrorCode != undefined && response.Authentication.ErrorCode != null) {
                if (isMobile) {
                    Alerts.ShowAlertFullScreen('Error with eWallet Deposit', response.Authentication.ErrorDescription, 'error');
                }
                else {
                    Alerts.ShowAlert('Error with eWallet Deposit', response.Authentication.ErrorDescription, 'error');
                }
            }
            else {
                var message = response.Authentication.HtmlResponse;
                if (response.Authentication.status == "PENDING") {
                    var ConfirmationCode = response.Authentication.Descriptor.split('|')[0]
                    arrayDirectZelle.AccountNumber = response.Authentication.Descriptor.split('|')[1];
                    arrayDirectZelle.AccountName = response.Authentication.Descriptor.split('|')[2];
                    arrayInitiateTransaction.Descriptor = ConfirmationCode;
                    //await setInitiateTransaction(response.Authentication);
                    setshowResponse(true);
                    setshowInstructions(false);
                }
                else if (response.Authentication.status == "REJECTED") {
                    if (isMobile) {
                        Alerts.ShowAlertFullScreen('eWallet Deposit Rejected', message, 'info');
                    }
                    else {
                        Alerts.ShowAlert('eWallet Deposit Rejected', message, 'info');
                    }

                }
            }
        });
        
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { ewamount } = Object.fromEntries(data.entries());
            if (ewamount != '') {
                setDisableControl(true);
                setIsProcessing(true);
                if (id === 'QuickerZelle') {
                    await InitiateTransaction(ewamount);
                }
                else {
                    await InitiateTransactionVoucher(ewamount);
                }
                setIsProcessing(false);
            }
            else {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
            }
        }
    }

    if (isValidate) {
        return <MissingAccountInfo Processor={'zelle'} isValidate={handleResponseFromComponent.bind(isValidate)} />;/* arrayZelleDeposits.ProcessorID*/
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="componentload">
            <div className="row">
                <div className="col-md-12 col-sm-12 icon-single-left logo-highlight">
                    <span className="xt-primary">
                        <img style={{ width: "140px" }} src="/svg/zelle.svg" />  Deposit using Zelle
                    </span>
                </div>
            </div>
            
            <form onSubmit={handleSubmit}>
                <div className={showInstructions ? "show-element" : null}>
                    {showInstructions && <div className="row" >
                        <div class="col-md-12" >
                            <div className="col-md-12 warning badge-zelle max-limit" role="alert">
                                <p className="center-block">
                                    <b>INSTRUCTIONS</b>
                                    <br />
                                    <br />1. Enter the deposit amount.
                                    <br />2. Once you click <b>"NEXT"</b> you will receive a confirmation code by email and SMS.<br />
                                    (You will need this code when making the deposit)
                                    <br />3. Make the deposit for the amount entered via ZELLE by using your Online Banking App or ZELLE App.<br />
                                    (ZELLE App is available on Apple App Store or Google Play Store)<br />
                                </p>
                            </div>
                        </div>
                        <div class="col-md-12" >
                            <br />
                        </div>
                        <div className="offset-md-3 col-md-6 col-sm-12 col-xs-12 mb-3" >
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-currency-cards d-none d-md-block" id="lblCurrency">
                                        <select className="form-control selectcurrency" disabled="" name="ccCurrency" required="" value={clientInfo.CurrencyCode}>
                                            <option value="" className="">Currency</option>
                                            <option value="USD">USD</option>
                                        </select>
                                    </span>
                                </div>
                                <input type="number" disabled={disableControl} onKeyPress={(event) => onlyNumbers(event)}  className="form-control color-gray border-teal" aria-label="Amount (to the nearest dollar)" placeholder="Amount" name="ewamount" max={arrayZelleDeposits?.MaxDeposit == 0 ? MAXLIMIT : arrayZelleDeposits?.MaxDeposit} min={arrayZelleDeposits?.MinDeposit == 0 ? 1 : arrayZelleDeposits?.MinDeposit} aria-describedby="lblCurrency" required="" />
                            </div>
                            <div className="d-flex flex-row ml-2 limit-text-center ">
                                Deposit Limits: Minimum {arrayZelleDeposits?.MinDeposit == 0 ? 1 : arrayZelleDeposits?.MinDeposit} USD Maximum {arrayZelleDeposits?.MaxDeposit == 0 ? 'No Limit' : arrayZelleDeposits?.MaxDeposit + ' USD'}
                            </div>
                        </div>
                        <Bonus ProcessorName="quickerzelle" />
                        <div className="col-md-12 mb-3-small mt-4">
                            <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4 d-none d-md-block" onClick={() => navigate('/deposits/')}>BACK</button>
                            <button type="submit" disabled={disableControl} className="btn btn-deposit btn-lightgreen btn-deposit-order mb-4 submit">NEXT</button>
                        </div>
                    </div>}
                </div>
            </form>

            <div className={showResponse ? "show-element" : null}>
                {showResponse && <div className="row componentload" >
                <div className="col-md-12 col-sm-12 col-xs-12 " >
                        <div className="row">
                        <div className="col-md-5 col-sm-3 col-xs-12 text-end">
                                ZELLE:
                            </div>
                            <div className="col-md-7 col-sm-9 col-xs-12">
                                <span style={{ fontSize:"large"}}> <b >{arrayDirectZelle.AccountNumber}</b></span>
                            </div>
                            <br/><br/>
                        <div className="col-md-5 col-sm-3 col-xs-12 text-end">
                                Payable to:
                            </div>
                            <div className="col-md-7 col-sm-9 col-xs-12 ">
                                <b >{arrayDirectZelle.AccountName}</b>
                            </div>
                            <br /><br />
                        <div className="col-md-5 col-sm-3 col-xs-12 text-end">
                                Code:
                            </div>
                            <div className="col-md-7 col-sm-9 col-xs-12">
                            <b >{arrayInitiateTransaction.Descriptor}</b>
                            </div>
                            <br /><br />
                        <div className="col-md-5 col-sm-3 col-xs-12 text-end">
                                Message or Memo:
                            </div>
                            <div className="col-md-7 col-sm-9 col-xs-12">
                                <b>Your Confirmation Code is <span style={{ fontSize:"x-large"}}> {arrayInitiateTransaction.Descriptor}</span>. Please make sure to include ONLY this code in the "MESSAGE" or "MEMO" field when making your deposit, or else your deposit will not be credited.</b>
                            <br /><br />
                            </div>
                            <div className="col-md-12 warning badge-zelle max-limit" role="alert">
                                    <p className="center-block">
                                        <b >IMPORTANT</b><br/><br/>
                                            Please make sure to include the email or SMS <b>confirmation code</b> in the "MESSAGE" or "MEMO"<br/>
                                                field when making the deposit in your Bank App or ZELLE App or else your deposit will not be credited.
                                    </p>
                            </div>
                        </div>
                </div>
                <div className="col-md-12 mb-3-small mt-4 actionbuttons">
                        <button type="button" className="btn btn-secondary btn-gray btn-back-order  mb-4" onClick={() => navigate('/deposits/')}>BACK</button>
                        <button className="btn btn-deposit btn-lightgreen btn-deposit-order mb-4 submit" onClick={() => navigate('/deposits/') }>FINISH</button>
                </div>
                </div>}
            </div>

            <div >
                {isProcessing && <Loading />}
            </div>

        </div>
    );
}