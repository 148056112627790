import React from 'react';
import clientInformation from '../login/ClientInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import {  useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import Loading from '../helpers/Loader';
import { IsValidToken, CheckCustomerData } from "../../services/AuthenticationService";
import { useParams } from 'react-router-dom';
import { onlyNumbers, BonusString } from '../helpers/common';
import { EWalletDeposit } from "../../services/DepositService";
import Bonus from '../Bonus';
import { isMobile } from 'react-device-detect';
import { MissingAccountInfo } from "../deposit/MissingAccountInfo.jsx";
import iframeResizer from 'iframe-resizer';

export const EwalletMethods = () => {
    const clientInfo = clientInformation();
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    let INSTANCEID = clientInfo.InstanceID;
    let url = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    const { id } = useParams();
    const [arrayRegularDeposits, setRegularDeposits] = useState(JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID == id; })[0]);
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false);
    const [showiFrame, setshowiFrame] = useState(false);
    const [showFrameTitle, setShowFrameTitle] = useState(true);
    const [showForm, setshowForm] = useState(true);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [arrayEwallet, setArrayEwallet] = useState([]);
    const [isValidate, setIsValidate] = useState(true);
    const [isSecureIdVisible, setIsSecureIdVisible] = useState(false);
    const [isAccountVisible, setIsAccountVisible] = useState(false);
    const [height, setHeight] = useState('900px');
    const iframeRef = React.useRef();

    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then(function (response) {
            validateFields();
            doCheckCustomerData();

        })
            .catch(function (error) {
                navigate('/expired/');
            })
        if (arrayRegularDeposits == undefined) {
            navigate('/deposits/');
        }
    }, [])


    function handleResponseFromComponent(response) {
        setIsValidate(response);
    }

    async function validateFields() {
        switch (arrayRegularDeposits.ImageName.toLowerCase()) {
            case "paysafecard":
            case "bitcoin":
            case "marketusa":
            case "wipay":
            case "changelly":
            case "wallet88":
            case "quickbuy":
                {
                    setIsSecureIdVisible(false);
                    setIsAccountVisible(false);
                    setHeight('700px');
                }
                break;
            case "wallet88emt":
                {
                    setIsSecureIdVisible(false);
                    setIsAccountVisible(false);
                    setHeight('700px');
                }
                break;
            case "quickervoucherv2":
            case "kribatta":
                {
                    setIsSecureIdVisible(false);
                    setIsAccountVisible(false);
                    //setHeight('900px');
                }
                break;
            default:
                {
                    setIsSecureIdVisible(false);
                    setIsAccountVisible(true);
                }
                break;
        }
    }

    async function doCheckCustomerData() {
        await CheckCustomerData(localStorage.getItem('Token'), arrayRegularDeposits.DepositID).then(async function (response) {
            if (!response.Authentication.AllComplete) {
                setIsValidate(true);
            }
            else {
                setIsValidate(false);
            }
        })
    };

    if (isValidate) {
        return <MissingAccountInfo Processor={arrayRegularDeposits.DepositID} isValidate={handleResponseFromComponent.bind(isValidate)} />;
    }

    async function doEWalletDeposit(Amount, Account, SecureID) {

        var Data =
        {
            "Token": localStorage.getItem('Token'),
            "eWalletAccount": Account,
            "eWalletPassword": SecureID,
            "ProcessorName": arrayRegularDeposits.DepositName,
            "InstanceID": INSTANCEID,
            "Amount": Amount,
            "CurrencyCode": "USD",
            "returnURL": "",
            "statusURL": "",
            "TransactionID": "-1",
            "IPv4Address": clientInfo.IPAddress,
            "BonusList": BonusString(JSON.parse(localStorage.getItem('ArrayBonus')))
        };

        await EWalletDeposit(Data).then(function (response) {
            
            setArrayEwallet(response.Authentication);

            if (response.Authentication.ErrorCode != undefined && response.Authentication.ErrorCode != null) {

                if (isMobile) {
                    Alerts.ShowAlertFullScreen('Error with eWallet Deposit', response.Authentication.ErrorDescription, 'error');
                }
                else {
                    Alerts.ShowAlert('Error with eWallet Deposit', response.Authentication.ErrorDescription, 'error');
                }                    
                
            }
            else {
                var message = response.Authentication.HtmlResponse;
                if (response.Authentication.status == "PENDING") {                    
                    var Link = response.Authentication.Descriptor;
                    if (Link != "" && Link != undefined && URL.canParse(Link)) {
                       
                        setshowiFrame(true);
                        setshowForm(false);
                        forceUpdate();
                    }
                    else {
                        if (arrayRegularDeposits.ImageName.toLowerCase() == 'premierint' ||
                            arrayRegularDeposits.ImageName.toLowerCase() == 'premieret' ||
                            arrayRegularDeposits.ImageName.toLowerCase() == 'premierplus') {
                            message += response.Authentication.Descriptor;

                            if (isMobile) {
                                Alerts.ShowAlertFullScreen('eWallet Deposit Pending', message, 'info');
                            }
                            else {
                                Alerts.ShowAlert('eWallet Deposit Pending', message, 'info');
                            }
                        }
                        else {
                            message += "<br/><br/><div class='bitcoinaddress'>Address: <b>" + response.Authentication.Descriptor + "</b></div>";
                            if (response.Authentication.Notes !== 'undefined' && response.Authentication.Notes != '')
                                message += "<br/><br/><div class='bitcoinaddress'>Please deposit the following btc amount:<br/> <b>" + response.Authentication.Notes + "</b></div>";
                            
                            if (isMobile) {
                                Alerts.ShowAlertFullScreen('eWallet Deposit Completed', message, 'info');
                            }
                            else {
                                Alerts.ShowAlert('eWallet Deposit Completed', message, 'info');
                            }
                            
                        }
                    }
                }
                else if (response.Authentication.status == "REJECTED") {
                    if (isMobile) {
                        Alerts.ShowAlertFullScreen('eWallet Deposit Rejected', message, 'info');
                    }
                    else {
                        Alerts.ShowAlert('eWallet Deposit Rejected', message, 'info');
                    }
                    
                }
                else {
                    if (isMobile) {
                        Alerts.ShowAlertFullScreen('eWallet Deposit Completed', message, 'info');
                    }
                    else {
                        Alerts.ShowAlert('eWallet Deposit Completed', message, 'info');
                    }
                }

            }            
            
        })
            .catch(function (error) {
                if (isMobile) {
                    Alerts.ShowAlertFullScreen('Error', 'Error making deposit', 'error');
                }
                else {
                    Alerts.ShowAlert('Error', 'Error making deposit', 'error');
                }
                
            })

       
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { amount, accountoremail, secureid } = Object.fromEntries(data.entries());
            if (amount != '') {
                setIsProcessing(true);
                await doEWalletDeposit(amount, accountoremail, secureid );
                setIsProcessing(false);
            }
            else {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
            }
        }
    }

    const handleIframeLoad = () => {
        try {
            setShowFrameTitle(false);
            iframeResizer({}, iframeRef.current);
        } catch (error) {
            console.warn('iframe-resizer failed to initialize, applying default height:', error);
            iframeRef.current.style.height = '900px'; // Set default height
        }
    };
    
    return (
        <div className="componentload">
            <form onSubmit={handleSubmit}>
            <div className="row m-4">
                <span className="icon-single-left txt-primary">
                    <img style={{ width: "100px", height: "100px" }} src={"/svg/" + arrayRegularDeposits.ImageName + ".svg"} /> Deposit using {id}
                </span>
                </div>
                {showiFrame && <div>
                    {showFrameTitle && <h5 id="frameTitle">Please wait, your form will load in a few seconds...</h5>}
                    <iframe ref={iframeRef} src={arrayEwallet.Descriptor} onLoad={handleIframeLoad} height={height} width='100%' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"></iframe></div>}
                {showForm && <div >
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="row">
                                {isSecureIdVisible && <div className="col-md-12 clear row-12" >
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <label for="secureid" className="col-md-offset-3 col-md-2  col-sm-offset-3 col-sm-2 col-xs-12">SecureID:</label>
                                        <div className="col-md-7 col-sm-7 col-xs-12">
                                            <input type="password" className="form-control" name="secureid" 
                                                aria-label="secure id" placeholder="SecureID" required />
                                        </div>
                                    </div>
                                </div>}
                                {isAccountVisible && <div className="col-md-12 clear row-12 mb-4">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <label for="secureid" className="col-md-offset-3 col-md-2  col-sm-offset-3 col-sm-2 col-xs-12">Account:</label>
                                        <div className="col-md-7 col-sm-7 col-xs-12">
                                            <input type="text" className="form-control" name="accountoremail"
                                                aria-label="account or email" placeholder="Account" required />
                                        </div>
                                    </div>
                                </div>}
                                <div className="col-md-7 col-sm-7 col-xs-12">
                                    <div className="col-md-12 m3 ">

                                        <div className="input-group mb-3  ">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-currency-cards d-none d-md-block" id="lblCurrency">
                                                        <select className="form-control selectcurrency" disabled name="CurrencyCode" required value={clientInfo.CurrencyCode == 'mBTC' ? 'USD' : clientInfo.CurrencyCode}>
                                                            <option value="" className="">Currency</option>
                                                            <option selected value="USD">USD</option>
                                                            <option value="EUR">EUR</option>
                                                            <option value="GBP">GBP</option>
                                                            <option value="CAD">CAD</option>
                                                        </select>
                                                    </span>
                                                </div>
                                                <input type="number" onKeyPress={(event) => onlyNumbers(event)} aria-label="Amount (to the nearest dollar)" className="form-control" id="amount" aria-describedby="lblCurrency" placeholder="Amount" max={arrayRegularDeposits?.MaxDeposit === 0 ? MAXLIMIT : arrayRegularDeposits?.MaxDeposit} min={arrayRegularDeposits?.MinDeposit === 0 ? 1 : arrayRegularDeposits?.MinDeposit} name="amount" required="" />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row ml-2 limit-text-center mb-4 ">
                                            Deposit Limits: Minimum {arrayRegularDeposits.MinDeposit == 0 ? 1 : arrayRegularDeposits.MinDeposit} USD Maximum {arrayRegularDeposits.MaxDeposit == 0 ? 'No Limit' : arrayRegularDeposits.MaxDeposit + ' USD'}
                                        </div>
                                    </div>

                                </div>
                                <Bonus ProcessorName={id}/>
                            </div>
                        </div>

                    </div>

                </div>}
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 " >

                    </div>
                    <div className="col-md-12 mb-3-small mt-4 actionbuttons">
                        <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4" onClick={() => navigate('/deposits/')}>BACK</button>
                        <button type="submit" className="btn btn-deposit btn-deposit-order mb-4 submit">DEPOSIT</button>
                    </div>
                </div>
            </form >
            <div>
                {isProcessing && <Loading />}
            </div>
        </div>
        
    );
}