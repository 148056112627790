import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import clientInformation from '../login/ClientInfo';
import Alerts from '../helpers/Alerts';
import { IsValidToken } from "../../services/AuthenticationService";
import { GetExpressLimits } from "../../services/DepositService";
import Loading from '../helpers/Loader';
import { CryptoDeposit, CreditCardDepositExpress } from "../../services/DepositService";
import Bonus from '../Bonus';
import { BonusString, onlyNumbers } from '../helpers/common';



export const ExpressMethods = () => {
    const clientInfo = clientInformation();
    let INSTANCEID = clientInfo.InstanceID;
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const [isLoading, setIsLoading] = useState(true);
    const [arrayDeposits, setArrayDeposits] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [arraySelected, setArraySelected] = useState([]);
    const [CryptoResponse, setCryptoResponse] = useState('');
    const [OriginalAmount, setOriginalAmount] = useState(0);
    const [currentKey, setCurrentKey] = useState(true);

    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then(async function (response) {

            await ExpressDepositsLimits();
        }

        ).catch(function (error) {
            setIsLoading(false);
            navigate('/expired/');

        })
    }, [])

    async function ExpressDepositsLimits() {
        await GetExpressLimits(localStorage.getItem('Token')).then(async function (response) {
            var i = 0;
            const filtered = response.Authentication.map(item => {

                const {
                    CardNumber,
                    CreditCardTypeID,
                    DepositID,
                    DepositName,
                    ExpYear,
                    IconURL,
                    ImageName,
                    MaxDeposit,
                    MinDeposit,
                    PaymentType,
                    ProcessorID,
                    RegCardID
                } = item; {
                    return {
                        Key: i++,
                        CardNumber,
                        CreditCardTypeID,
                        DepositID,
                        DepositName,
                        ExpYear,
                        IconURL,
                        ImageName,
                        MaxDeposit,
                        MinDeposit,
                        PaymentType,
                        ProcessorID,
                        RegCardID
                    };
                }
            });
            await setArrayDeposits(filtered.filter(function (element) { return element !== undefined; }));

            var array = filtered.filter(function (element) { return element !== undefined; });
            if ((Array.isArray(array) && array.length === 0) || (array["Authentication"] != undefined && array["Authentication"].length === 0)) {
                navigate('/deposits/');
            }

            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            clientInfo.Error = false;
            Alerts.ShowAlert('Error', 'Error getting express deposit limits info: ' + error.response.data.Authentication.ErrorDescription, 'error');
        })
    }

    const navigate = useNavigate();

    const handleMethodSelect = (name) => {
        navigate(`/deposits/${name}`);
    }

    const handlePayoutSelect = (name) => {
        navigate(`/payouts/${name}`);
    }


    async function doCryptoDeposit(Amount) {
        setIsProcessing(true);
        await CryptoDeposit(localStorage.getItem('Token'),
            arraySelected.DepositName,
            INSTANCEID,
            Amount,
            clientInfo.CurrencyCode,
            '',
            '',
            '-1',
            clientInfo.IPAddress,
            '',
            '',
            (localStorage.getItem('ArrayBonus') != '' ? BonusString(JSON.parse(localStorage.getItem('ArrayBonus'))) : '')
         ).then(async function (response) {
            await setCryptoResponse(response.Authentication);
            setIsProcessing(false);
            await Alerts.ShowAlert('Deposit Status', await response.Authentication.HtmlResponse + ' <p>TransactionID: ' + await response.Authentication.TransactionID + ' </p>' + ' <p>Send ' + await response.Authentication.Amount + '  to this address: </p>' + ' <p>' + await response.Authentication.Descriptor + '</p>', 'info');
        })
            .catch(function (error) {
                setIsProcessing(false);
                Alerts.ShowAlert('Error', 'Error making deposit', 'error');
            })

    }

    async function doCreditCardDepositExpress(Amount) {
        setIsProcessing(true);
        await CreditCardDepositExpress(localStorage.getItem('Token'), '-1', arraySelected.RegCardID, Amount, localStorage.getItem('CurrencyCode'), clientInfo.IPAddress, '', '', INSTANCEID, '', '', '', '', BonusString(JSON.parse(localStorage.getItem('ArrayBonus'))), '').then(async function (response) {
            setIsProcessing(false);
            await Alerts.ShowAlert('Deposit Status', await response.Authentication.HtmlResponse, 'info');
        })
            .catch(function (error) {
                setIsProcessing(false);
                Alerts.ShowAlert('Error', 'Error making deposit', 'error');
            })

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { ewamount } = Object.fromEntries(data.entries());
            if (ewamount != '') {
                if (arraySelected.length != 0) {
                    setOriginalAmount(ewamount);
                    if (arraySelected.PaymentType.toLowerCase() == "crypto")
                        doCryptoDeposit(ewamount);
                    else
                        doCreditCardDepositExpress(ewamount);
                }
                else {
                    Alerts.ShowAlert('Warning', 'Deposit method required', 'warning');
                }
            }
            else {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
            }
        }
    }

    const PaymentSelection = async (id) => {
        setCurrentKey(id);
        await setArraySelected('');
        await setArraySelected(arrayDeposits.filter(function (element) { return element.Key == id; })[0]);

    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="componentload">
            <form onSubmit={handleSubmit}>
                <div className="title">
                    <div className="container">
                        <h3 className="txt-primary text-title-color">Express deposit</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxl-6 col-md-6 col-sm-6 col-xs-6">
                        <div onClick={() => handleMethodSelect('')} className="box-deposit othermethod-color mb-4 text-center text-wrap" >
                            <span className="othermethod-text text-center">USE OTHER DEPOSIT METHODS</span>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-md-6 col-sm-6 col-xs-6">
                        <div onClick={() => handlePayoutSelect('')} className="box-deposit payoutmethod-color mb-4 text-center text-wrap" >
                            <span className="othermethod-text text-center">WITHDRAWAL OPTIONS</span>
                        </div>

                    </div>
                    {arrayDeposits.map((item, index) => (
                        <div key={index} className="col-xxl-4 col-md-6 col-sm-6 col-xs-6">
                            <a onClick={() => PaymentSelection(item.Key)} className="box-deposit pointer mb-4">
                                <div className="row">
                                    <div className="col-md-3 col-sm-12">
                                        <span className="checkSelect">
                                            <input onChange={() => PaymentSelection(item.Key)} type="radio" name="rbOption" id="rbOption" value={item.Key} checked={item.Key === currentKey} />
                                        </span>
                                        <span className="icon-single">
                                            <img alt="" src={"/svg/" + item.ImageName + ".svg"} />
                                        </span>
                                    </div>
                                    <div className="col-md-5 col-sm-12 text-center creditcard-font">
                                        <div>{item.CardNumber}</div>
                                        {item.ExpYear != null && <div className="d-none d-md-block ">Exp XX/{item.ExpYear}</div>}
                                    </div>
                                    <div className="col-md-4 limit-text d-none d-md-block ">
                                        <span>Min:${item.MinDeposit == 0 ? 1 : item.MinDeposit} <br /> Max:{item.MaxDeposit == 0 ? 'No Limit' : '$' + item.MaxDeposit}</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}

                   
                </div>

                <div className="row">
                    <div className="col-xxl-4 col-md-6 col-sm-12 col-xs-12 offset-md-3 offset-xxl-4 mt-3 mb-3">
                        <div className="btn-group">
                            <div className="input-group ">
                                <div className="input-group-prepend">
                                    <span className="input-group-currency-cards  d-none d-md-block" id="lblCurrency">
                                        <select className="form-control selectcurrency" disabled="" name="ccCurrency" required="" value={clientInfo.CurrencyCode}>
                                            <option value="" className="">Currency</option>
                                            <option value="USD">USD</option>
                                            <option value="EUR">EUR</option>
                                            <option value="GBP">GBP</option>
                                            <option value="CAD">CAD</option>
                                        </select>
                                    </span>
                                </div>
                                <input type="number" onKeyPress={(event) => onlyNumbers(event)} aria-label="Amount (to the nearest dollar)" aria-describedby="lblCurrency" className="form-control color-gray border-teal " placeholder="AMOUNT" name="ewamount" required=""
                                    max={arraySelected?.MaxDeposit == 0 ? MAXLIMIT : arraySelected?.MaxDeposit} min={arraySelected?.MinDeposit == 0 ? 1 : arraySelected?.MinDeposit} style={{ minWidth: "130px", maxWidth:"210px"}} />
                            </div>
                            <button type="submit" className="btn btn-deposit-express btn-lightgreen btn-deposit-order submit" style={{ minWidth: "130px", maxWidth: "185px" }}>DEPOSIT</button>
                        </div>
                    </div>
                </div>
                <hr />
                <Bonus />
                {/* <div className="row">
                    <div className="col-md-12 mb-3-small mt-4">
                        <button type="submit" className="btn btn-deposit btn-lightgreen btn-deposit-order mb-4 submit">DEPOSIT</button> 
                    </div>
                </div>
                <hr /> */}
                
            </form>
            <div>
                {isProcessing && <Loading />}
            </div>
        </div>
    );
}