import { Footer } from "../components/Footer"
import { PriorityMethods } from "../components/deposit/PriorityMethods"
import { CreditCardMethods } from "../components/deposit/CreditCardMethods"


export const DepositsCreditCardPage = () => {
    return (
        <div className="container">
            <PriorityMethods />
            <CreditCardMethods />
            <Footer />
        </div>
    );
}