import { Footer } from "../components/Footer"
import { PriorityMethods } from "../components/deposit/PriorityMethods"
import { ECheckMethods } from "../components/deposit/ECheckMethods"


export const DepositECheckPage = () => {
    return (
        <div className="container">
            <PriorityMethods />
            <ECheckMethods />
            <Footer />
        </div>
    );
}