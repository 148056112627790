import { Footer } from "../components/Footer"
import { PriorityMethods } from "../components/deposit/PriorityMethods"
import { CryptoMethods } from "../components/deposit/CryptoMethods"


export const DepositCryptoPage = () => {
    return (
        <div className="container">
            <PriorityMethods />
            <CryptoMethods />
            <Footer />
        </div>
    );
}