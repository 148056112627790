import clientInformation from '../login/ClientInfo';
import { RequestECheckPayout } from "../../services/PayoutService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import Loading from '../helpers/Loader';
import { IsValidToken, GetCustomerInfo, GetCountries, GetStatesbyCountry } from "../../services/AuthenticationService";
import BeatLoader from "react-spinners/BeatLoader";
import { onlyNumbers, BonusString } from '../helpers/common';
import { isMobile } from 'react-device-detect';

export const PayoutCheckMethods = () => {
    const clientInfo = clientInformation();
    let INSTANCEID = clientInfo.InstanceID;
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const [isProcessing, setIsProcessing] = useState(false);
    const { id } = useParams();
    const [arrayPayouts, setPayouts] = useState(JSON.parse(localStorage.getItem('arrayPayoutsMethods')).filter(function (element) { return element.PayoutID === id; })[0]);
    const navigate = useNavigate();
    const [arrayCustomerInfo, setArrayCustomerInfo] = useState([]);
    const [showComponent, setshowComponent] = useState(false);
    const [OriginalAmount, setOriginalAmount] = useState(0);
    const [arrayCountries, setArrayCountries] = useState([]);
    const [arrayStates, setArrayStates] = useState([]);
    const [stateCode, setStateCode] = useState('');
    const [countryCode, setCountryCode] = useState('');

    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            await GetCountries(localStorage.getItem('Token')).then(async function (response) {
                var i = 0;
                const filtered = response.Authentication.map(item => {
                    const {
                        Code,
                        Name } = item;

                    return { Key: i++, Code, Name }
                });
                setArrayCountries(filtered);
                setCountryCode('US');
                handleStatebyCountry('US', true);
            });
        })
            .catch(function (error) {
                navigate('/expired/');
            })

        if (arrayPayouts === undefined) {
            navigate('/payouts/');
        }
        CustomerInfo();
        
    }, [])

    async function CustomerInfo() {
        await GetCustomerInfo(localStorage.getItem('Token')).then(function (response) {
            if (response.Authentication.ErrorDescription != "Invalid input data") {
                setArrayCustomerInfo(response.Authentication);

            }
            else {

                Alerts.ShowAlert('Error', 'Error getting customer info: ' + response.Authentication.ErrorDescription, 'error');

            }
        })
            .catch(function (error) {

                Alerts.ShowAlert('Error', 'Error getting customer info: ' + error.response.data.Authentication.ErrorDescription, 'error');
            })
    }

    const handleStatebyCountry = async (selectedCountryCode, setState) => {
        await GetStatesbyCountry(selectedCountryCode).then(async function (response) {
            var i = 0;
            const filtered = response.Authentication.map(item => {
                const {
                    Code,
                    Name } = item;

                return { Key: i++, Code, Name }
            });
            setArrayStates(filtered);
            if (setState) {
                if (filtered.length > 1)
                    setStateCode(filtered[1].Code);
                else
                    setStateCode(filtered[0].Code);
            }
        })
    }

    async function doRequestCheckPayout(Amount, accountnumber, routingnumber, bankname, bankprovince, bankcity, bankaddress, firstname, lastname, email, telephone, city, zipcode, address) {
        if (parseFloat(Amount.replace(",", "").replace(" ", "")) < parseFloat(clientInfo.WithdrawalBalance.replace(",", "")) && parseFloat(Amount.replace(",", "").replace(" ", "")) > 0) {
            var PayoutData = {
                "Token": localStorage.getItem('Token'),
                "Amount": Amount.replace(",", ""),
                "CurrencyCode": clientInfo.CurrencyCode,
                "ProcessorName": arrayPayouts?.PayoutID,
                "IPv4Address": clientInfo.IPAddress,
                "Comments": '',
                "BankName": bankname,
                "BankCity": bankcity,
                "BankState": bankprovince,
                "BankAddress": bankaddress,
                "CheckAccountNumber": accountnumber,
                "CheckRoutingNumber": routingnumber,
                "Firstname": firstname,
                "Lastname": lastname,
                "Email": email,
                "Phone": telephone,
                "CountryCode": countryCode,
                "StateCode": stateCode,
                "City": city,
                "Zipcode": zipcode,
                "Address": address,
                
                };

            await RequestECheckPayout(PayoutData).then(function (response) {

                if (response.Authentication.ErrorCode === "") {
                    if (isMobile)
                        Alerts.ShowAlertFullScreen('Withdrawal Status', response.Authentication.HtmlResponse, 'info');
                    else
                        Alerts.ShowAlert('Withdrawal Status', response.Authentication.HtmlResponse, 'info');
                    navigate('/payouts/');
                } else {
                    if (isMobile)   
                        Alerts.ShowAlertFullScreen('Withdrawal Status', response.Authentication.HtmlResponse, 'warning');
                    else
                        Alerts.ShowAlert('Withdrawal Status', response.Authentication.HtmlResponse, 'warning');
                }
            })
                .catch(function (error) {
                    Alerts.ShowAlert('Withdrawal Status', error.response.data.Authentication.HtmlResponse, 'error');
                    //Alerts.ShowAlert('Error', 'Error making deposit', 'error');
                })
        }
        else {
            if (isMobile)
                Alerts.ShowAlertFullScreen('Withdrawal Status', 'amount not permitted, please check your withdrawal balance', 'warning');
            else
                Alerts.ShowAlert('Withdrawal Status', 'amount not permitted, please check your withdrawal balance', 'warning');
        }

    }

    const handleSubmit = async (event) => {
        event.preventDefault();        
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { amount, accountnumber, routingnumber, bankname, bankprovince, bankcity, bankaddress, firstname, lastname, email, telephone, city, zipcode, address } = Object.fromEntries(data.entries());
            
            if (amount != '') {
                setIsProcessing(true);
                setOriginalAmount(amount);
                await doRequestCheckPayout(amount, accountnumber, routingnumber, bankname, bankprovince, bankcity, bankaddress, firstname, lastname, email, telephone, city, zipcode, address );
                setIsProcessing(false);
                

            }
            else {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
            }

        }
    }

    return (
        <div className="componentload">
            <form onSubmit={handleSubmit}>
                <div className="row m-4">
                    <span className="icon-single-left txt-primary">
                        <img style={{ width: "100px", height: "100px" }} src={"/svg/" + arrayPayouts?.ImageName + ".svg"} />  <span className="payout-title">Withdraw using {arrayPayouts?.PayoutID} </span>
                    </span>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 mb-3">
                        <input type="text" className="form-control" id="accountnumber" aria-describedby="lblAddress" placeholder="Account Number" name="accountnumber" required="required" />
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <input type="text" className="form-control" id="routingnumber" aria-describedby="lblAddress" placeholder="9 digit Routing (ABA)" name="routingnumber" maxLength="9" required="required" />
                    </div>
                    <div className="col-md-4 col-sm-12 m3">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-currency-cards d-none d-md-block" id="lblCurrency">
                                    <select className="form-control selectcurrency" disabled name="ccCurrency" required value={clientInfo.CurrencyCode}>
                                        <option value="" className="">Currency</option>
                                        <option value="USD">USD</option>
                                    </select>
                                </span>
                            </div>
                            <input type="number" onKeyPress={(event) => onlyNumbers(event)} aria-label="Amount (to the nearest dollar)" className="form-control" id="amount" aria-describedby="lblCurrency" placeholder="Amount" max={arrayPayouts?.MaxPayout === 0 ? MAXLIMIT : arrayPayouts?.MaxPayout} min={arrayPayouts?.MinPayout === 0 ? 1 : arrayPayouts?.MinPayout} name="amount" required="required" />
                        </div>

                        <div className="d-flex flex-row ml-2 limit-text-center mb-4 ">
                            PayoutLimits: Minimum {arrayPayouts?.MinPayout === 0 ? 1 : arrayPayouts?.MinPayout} USD Maximum {arrayPayouts?.MaxPayout === 0 ? 'No Limit' : arrayPayouts?.MaxPayout + ' USD'}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-title-color mb-4">
                        <h3>Bank information</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="bankname" className=" col-sm-12">Bank name:</label>
                        <input type="text" className="form-control" id="bankname" aria-describedby="bankname" placeholder="bank name" name="bankname" required="required" />
                    </div>
                   
                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="bankcountry" className="col-sm-12">Bank Country:</label>
                        <select className="form-select border-teal" value={countryCode} onChange={(event) => { setCountryCode(event.target.value); handleStatebyCountry(event.target.value, true) }}
                            tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Select the Country" required disabled>
                            {arrayCountries.map((item) => <option key={item.Key} value={item.Code}>{item.Name}</option>
                            )}
                        </select>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="bankprovince" className=" col-sm-12">Bank State:</label>
                        <select className="form-select border-teal" value={stateCode} onChange={(event) => setStateCode(event.target.value)} name="bankprovince" id="bankprovince"
                            tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Select the State" required>
                            {arrayStates.map((item) => <option key={item.Key} value={item.Code}>{item.Name}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="bankcity" className=" col-sm-12">Bank City:</label>
                        <input type="text" className="form-control" id="bankcity" aria-describedby="bankcity" placeholder="bank city" name="bankcity" required="required" />
                    </div>
                    <div className="col-md-8 col-sm-12 mb-3">
                        <label for="bankaddress" className=" col-sm-12">Bank Address:</label>
                        <input type="text" className="form-control" id="bankaddress" aria-describedby="bankaddress" placeholder="bank address" name="bankaddress" required="required" />
                    </div>
                   
                </div>
                <div className="row">
                    <div className="col-12 text-title-color mt-4 mb-4">
                        <h3>Personal information</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="firstname" className=" col-sm-12">First Name:</label>
                        <input type="text" className="form-control" id="firstname" aria-describedby="firstname" placeholder="first name" name="firstname" required="required" value={arrayCustomerInfo.Name} />
                    </div>

                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="lastname" className="col-sm-12">Last name:</label>
                        <input type="text" className="form-control" id="lastname" aria-describedby="lastname" placeholder="last name" name="lastname" required="required" value={arrayCustomerInfo.LastName} />
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="email" className=" col-sm-12">Email:</label>
                        <input type="email" className="form-control" id="email" aria-describedby="email" placeholder="email" name="email" required="required" value={arrayCustomerInfo.Email} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="telephone" className=" col-sm-12">Phone #:</label>
                        <input type="number" className="form-control" id="telephone" aria-describedby="telephone" placeholder="phone #" name="telephone" required="required" value={arrayCustomerInfo.Phone} />
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="country" className="col-sm-12">Country:</label>
                        <input type="text" className="form-control color-gray border-teal" name="country" aria-label="country" value={arrayCustomerInfo.Country} placeholder={arrayCustomerInfo.Country == '' ? "Country" : arrayCustomerInfo.Country} disabled="disabled" />
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="state" className=" col-sm-12">State:</label>
                        <input type="text" className="form-control color-gray border-teal" name="state" aria-label="state" value={arrayCustomerInfo.State} placeholder={arrayCustomerInfo.State == '' ? "State / Prov" : arrayCustomerInfo.State} disabled="disabled" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="city" className=" col-sm-12">City:</label>
                        <input type="text" className="form-control" id="city" aria-describedby="city" placeholder="city" name="city" required="required" value={arrayCustomerInfo.City} />
                    </div>

                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="zipcode" className="col-sm-12">Zipcode:</label>
                        <input type="text" className="form-control" id="zipcode" aria-describedby="zipcode" placeholder="zipcode" name="zipcode" required="required" value={arrayCustomerInfo.ZipCode} />
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <label for="address" className=" col-sm-12">Address:</label>
                        <input type="text" className="form-control" id="address" aria-describedby="address" placeholder="address" name="address" required="required" value={arrayCustomerInfo.Address} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 " >

                    </div>
                    <div className="col-md-12 mb-3-small mt-4 actionbuttons">
                        <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4" onClick={() => navigate('/payouts/')}>BACK</button>
                        <button type="submit" className="btn btn-deposit btn-deposit-order mb-4 submit">SUBMIT</button>
                    </div>
                </div>


            </form>
            <div>
                {isProcessing && <Loading/>}
            </div>
        </div>
    );

}