

function getDate() {
    const today = new Date();
    const year = today.getFullYear();
    return `${year}`;
}

export const Footer = () => {
    return <div className="mt-4"> <p>Copyright &copy; { getDate() } Mnet All Rights Reserved</p>    </div>
}