import { Footer } from "../components/Footer"
import { PriorityMethods } from "../components/deposit/PriorityMethods"
import { P2PMethods } from "../components/deposit/P2PMethods"


export const DepositsP2PPage = () => {
    return (
        <div className="container">
            <PriorityMethods />
            <P2PMethods />
            <Footer />
        </div>
    );
}