import clientInformation from '../login/ClientInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import { IsValidToken, GetCustomerInfo, CheckCustomerData } from "../../services/AuthenticationService";
import { onlyNumbers, BonusString } from '../helpers/common';
import { isMobile } from 'react-device-detect';
import Bonus from '../Bonus';
import Loading from '../helpers/Loader'
import { MissingAccountInfo } from "../deposit/MissingAccountInfo.jsx";

export const ECheckMethods = () => {

    const clientInfo = clientInformation();
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    let INSTANCEID = clientInfo.InstanceID;
    const [isLoading, setIsLoading] = useState(true);
    const [isValidate, setIsValidate] = useState(true);
    const navigate = useNavigate();
    const [arrayCustomerInfo, setArrayCustomerInfo] = useState([]);
    const [arrayRegularDeposits, setRegularDeposits] = useState(JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID ==  'Check'; })[0]);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then(async function () {
            doCheckCustomerData();
            
             setIsLoading(false);
        })
            .catch(function (error) {
                setIsLoading(false);
                navigate('/expired/');
            })
        CustomerInfo();
    }, [])

    async function doCheckCustomerData() {
        await CheckCustomerData(localStorage.getItem('Token'), 'check').then(async function (response) {
            if (!response.Authentication.AllComplete) {
                setIsValidate(true);
            }
            else {
                setIsValidate(false);
            }
        })
    };

    async function CustomerInfo() {
        await GetCustomerInfo(localStorage.getItem('Token')).then(function (response) {
            if (response.Authentication.ErrorDescription != "Invalid input data") {
                setArrayCustomerInfo(response.Authentication);

            }
            else {

                Alerts.ShowAlert('Error', 'Error getting customer info: ' + response.Authentication.ErrorDescription, 'error');

            }
        })
            .catch(function (error) {

                Alerts.ShowAlert('Error', 'Error getting customer info: ' + error.response.data.Authentication.ErrorDescription, 'error');
            })
    }

    function handleResponseFromComponent(response) {
        setIsValidate(response);
    }

    if (isValidate) {
        return <MissingAccountInfo Processor={'crypto'} isValidate={handleResponseFromComponent.bind(isValidate)} />;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { amount, accountoremail, secureid } = Object.fromEntries(data.entries());
            if (amount != '') {
                setIsProcessing(true);
                
                setIsProcessing(false);
            }
            else {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
            }
        }
    }

    return (
        <div className="componentload">
            <form onSubmit={handleSubmit}>
                <div className="row m-4">
                    <span className="icon-single-left txt-primary">
                        <img style={{ width: "100px", height: "100px" }} src={"/svg/" + arrayRegularDeposits.ImageName + ".svg"} /> Deposit using e-Check
                    </span>
                </div>
                <div className="row">
                    <div className="col-md-1 d-none d-md-block"></div>
                    <div className="col-md-10 col-sm-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-group mb-3  ">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-currency-cards d-none d-md-block" id="lblCurrency">
                                                <select className="form-control selectcurrency" disabled name="CurrencyCode" required="" value={clientInfo.CurrencyCode == 'mBTC' ? 'USD' : clientInfo.CurrencyCode}>
                                                    <option value="" className="">Currency</option>
                                                    <option selected value="USD">USD</option>
                                                    <option value="EUR">EUR</option>
                                                    <option value="GBP">GBP</option>
                                                    <option value="CAD">CAD</option>
                                                </select>
                                            </span>
                                        </div>
                                        <input type="number" onKeyPress={(event) => onlyNumbers(event)} aria-label="Amount (to the nearest dollar)" className="form-control" id="amount" aria-describedby="lblCurrency" placeholder="Amount" max={arrayRegularDeposits?.MaxDeposit === 0 ? MAXLIMIT : arrayRegularDeposits?.MaxDeposit} min={arrayRegularDeposits?.MinDeposit === 0 ? 1 : arrayRegularDeposits?.MinDeposit} name="amount" required="" />
                                    </div>
                                </div>
                                <div className="d-flex flex-row ml-2 limit-text-center mb-4 ">
                                    Deposit Limits: Minimum {arrayRegularDeposits.MinDeposit == 0 ? 1 : arrayRegularDeposits.MinDeposit} USD Maximum {arrayRegularDeposits.MaxDeposit == 0 ? 'No Limit' : arrayRegularDeposits.MaxDeposit + ' USD'}
                                </div>
                            </div>
                            <div className="col-md-6  mb-3">
                                    <label class="d-md-none mb-3">Account Type</label>
                                <select class="form-control form-select border-teal" name="accounttype" aria-label="Account Type" placeholder="Account Type" required="">
                                        <option value="PC" selected="selected">Personal Checking</option>
                                        <option value="PS">Personal Savings</option>
                                        <option value="CC">Commercial Checking</option>
                                    </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 d-none d-md-block"></div>
                </div>
                <div className="row">
                    <div className="col-md-1 d-none d-md-block"></div>
                    <div className="col-md-10 col-sm-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="country" className="col-md-4 col-sm-12">Routing Number:</label>
                                    <div className="col-md-8 col-sm-12 ">
                                        <input type="text" class="form-control color-gray border-teal" name="RoutingNumber" aria-label="RoutingNumber" placeholder="RoutingNumber" maxlength="50" required="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="state" className="col-md-4 col-sm-12">AccountNumber:</label>
                                    <div className="col-md-8 col-sm-12">
                                        <input type="text" class="form-control color-gray border-teal" name="AccountNumber" aria-label="AccountNumber" placeholder="AccountNumber" maxlength="50"  required="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 d-none d-md-block"></div>
                </div>
                <div className="row">
                    <div className="col-md-1 d-none d-md-block"></div>
                    <div className="col-md-10 col-sm-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="bankname" className="col-md-4 col-sm-12">Bank Name:</label>
                                    <div className="col-md-8 col-sm-12 ">
                                        <input type="text" class="form-control color-gray border-teal" name="bankname" aria-label="Bank Name" placeholder="BankName" maxlength="30" required="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="Firstname" className="col-md-4 col-sm-12">First Name:</label>
                                    <div className="col-md-8 col-sm-12">
                                        <input type="text" class="form-control color-gray border-teal" name="Firstname" aria-label="Firstname" placeholder="Firstname" maxlength="30" value={arrayCustomerInfo.Name} required="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 d-none d-md-block"></div>
                </div>
                <div className="row">
                    <div className="col-md-1 d-none d-md-block"></div>
                    <div className="col-md-10 col-sm-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="Lastname" className="col-md-4 col-sm-12">Last Name:</label>
                                    <div className="col-md-8 col-sm-12 ">
                                        <input type="text" class="form-control color-gray border-teal" name="Lastname" aria-label="Lastname" placeholder="Lastname" maxlength="30" value={arrayCustomerInfo.LastName} required="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="City" className="col-md-4 col-sm-12">City:</label>
                                    <div className="col-md-8 col-sm-12">
                                        <input type="text" class="form-control color-gray border-teal" name="City" aria-label="City" placeholder="City" maxlength="20" value={arrayCustomerInfo.City} required="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 d-none d-md-block"></div>
                </div>
                <div className="row">
                    <div className="col-md-1 d-none d-md-block"></div>
                    <div className="col-md-10 col-sm-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="Address" className="col-md-4 col-sm-12">Address:</label>
                                    <div className="col-md-8 col-sm-12 ">
                                        <input type="text" class="form-control color-gray border-teal" name="Address" aria-label="Address" placeholder="Address" maxlength="50" value={arrayCustomerInfo.Address} required="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="Address2" className="col-md-4 col-sm-12">Address 2:</label>
                                    <div className="col-md-8 col-sm-12">
                                        <input type="text" class="form-control color-gray border-teal" name="Address2" aria-label="Address2" placeholder="Address2" maxlength="50" value={arrayCustomerInfo.Address2}  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 d-none d-md-block"></div>
                </div>
                <div className="row">
                    <div className="col-md-1 d-none d-md-block"></div>
                    <div className="col-md-10 col-sm-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="country" className="col-md-4 col-sm-12">Country:</label>
                                    <div className="col-md-8 col-sm-12 ">
                                        <input type="text" className="form-control color-gray border-teal" name="country" aria-label="country" value={arrayCustomerInfo.Country} placeholder={arrayCustomerInfo.Country == '' ? "Country" : arrayCustomerInfo.Country} disabled="disabled" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="state" className="col-md-4 col-sm-12">State / Prov:</label>
                                    <div className="col-md-8 col-sm-12">
                                        <input type="text" className="form-control color-gray border-teal" name="state" aria-label="state" value={arrayCustomerInfo.State} placeholder={arrayCustomerInfo.State == '' ? "State / Prov" : arrayCustomerInfo.State} disabled="disabled" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 d-none d-md-block"></div>
                </div>
                <div className="row">
                    <div className="col-md-1 d-none d-md-block"></div>
                    <div className="col-md-10 col-sm-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="ZipCode" className="col-md-4 col-sm-12">Zip:</label>
                                    <div className="col-md-8 col-sm-12 ">
                                        <input type="text" class="form-control color-gray border-teal" name="ZipCode" aria-label="ZipCode" placeholder="ZipCode" maxlength="12" value={arrayCustomerInfo.ZipCode} required="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="Phone" className="col-md-4 col-sm-12">Phone:</label>
                                    <div className="col-md-8 col-sm-12">
                                        <input type="text" class="form-control color-gray border-teal" name="Phone" aria-label="Phone" placeholder="Phone" maxlength="20" value={arrayCustomerInfo.Phone} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 d-none d-md-block"></div>
                </div>
                <div className="row">
                    <div className="col-md-1 d-none d-md-block"></div>
                    <div className="col-md-10 col-sm-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="ZipCode" className="col-md-4 col-sm-12">Email:</label>
                                    <div className="col-md-8 col-sm-12 ">
                                        <input type="text" class="form-control color-gray border-teal" name="Email" aria-label="Email" placeholder="Email" maxlength="12" value={arrayCustomerInfo.Email} required="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row  mb-3">
                                    <label for="Phone" className="col-md-4 col-sm-12">Description:</label>
                                    <div className="col-md-8 col-sm-12">
                                        <input type="text" class="form-control color-gray border-teal" name="Description" aria-label="Description" placeholder="Description" maxlength="20"  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 d-none d-md-block"></div>
                </div>
                <Bonus />
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 " >

                    </div>
                    <div className="col-md-12 mb-3-small mt-4 actionbuttons">
                        <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4" onClick={() => navigate('/deposits/')}>BACK</button>
                        <button type="submit" className="btn btn-deposit btn-deposit-order mb-4 submit">DEPOSIT</button>
                    </div>
                </div>
            </form>
        </div>
        );

}
