import clientInformation from '../login/ClientInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState, useRef  } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import { IsValidToken, CheckCustomerData } from "../../services/AuthenticationService";
import { CryptoDeposit, GetCryptoNetworks } from "../../services/DepositService";
import { onlyNumbers, BonusString } from '../helpers/common';
import { isMobile } from 'react-device-detect';
import Bonus from '../Bonus';
import Loading from '../helpers/Loader'
import { MissingAccountInfo } from "../deposit/MissingAccountInfo.jsx";

export const CryptoMethods = () => {
    const clientInfo = clientInformation();
    const [isLoading, setIsLoading] = useState(true);
    let INSTANCEID = clientInfo.InstanceID;
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const [isProcessing, setIsProcessing] = useState(false);
    const { id } = useParams();
    const [arrayRegularDeposits, setRegularDeposits] = useState(JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID === id; })[0]);
    const [arrayCryptoNetworks, setCryptoNetworks] = useState([]);
    const navigate = useNavigate();
    const [showComponent, setshowComponent] = useState(false);
    const [CryptoResponse, setCryptoResponse] = useState('');
    const [cryptoNetwork, setCryptoNetwork] = useState('bitcoin');
    const [OriginalAmount, setOriginalAmount] = useState(0);
    const scrollRef = useRef('qrcode');
    const [isValidate, setIsValidate] = useState(true);
   
    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then(async function () {
            doCheckCustomerData();
            await GetCryptoNetworks(localStorage.getItem('Token'), id).then(async function (response) {
                await setCryptoNetworks(response.Authentication.Networks.split(','));
                setIsLoading(false);
            })
        })
            .catch(function (error) {
                setIsLoading(false);
                navigate('/expired/');
            })

        if (arrayRegularDeposits === undefined) {
            navigate('/deposits/');
        }
        switch (id.toLowerCase()) {
            case 'bitcoin':
                setCryptoNetwork('bitcoin');
                break;
            case 'litecoin':
                setCryptoNetwork('litecoin');
                break;
            case 'bitcoincash':
                setCryptoNetwork('bitcoincash');
                break;
            case 'bnb':
                setCryptoNetwork('bsc-bep20');
                break;
            default:
                setCryptoNetwork('ethereum-erc20');
                break;
        }
        
    }, [])

    function handleResponseFromComponent(response) {
        setIsValidate(response);
    }

    async function doCheckCustomerData() {
        await CheckCustomerData(localStorage.getItem('Token'), 'crypto').then(async function (response) {
            if (!response.Authentication.AllComplete) {
                setIsValidate(true);
            }
            else {
                setIsValidate(false);
            }
        })
    };

    if (isValidate) {
        return <MissingAccountInfo Processor={'crypto'} isValidate={handleResponseFromComponent.bind(isValidate)} />;
    }

    async function doCryptoDeposit(Amount, cryptoNetwork, CurrencyCode) {
        if (arrayCryptoNetworks.length > 0 && arrayCryptoNetworks[0] === '' || cryptoNetwork === undefined) {
            cryptoNetwork = '';
        }

        await CryptoDeposit(localStorage.getItem('Token'),
            arrayRegularDeposits.DepositName, INSTANCEID,
            Amount, CurrencyCode,
            '',
            '',
            '-1',
            clientInfo.IPAddress,
            cryptoNetwork,
            clientInfo.Udf1,
            BonusString(JSON.parse(localStorage.getItem('ArrayBonus')))).then(function (response) {
            setCryptoResponse(response.Authentication);

             if (response.Authentication.status === "REJECTED") {
                if (isMobile) {
                    Alerts.ShowAlertFullScreen('Deposit Status', 'Your Transaction was ' + response.Authentication.status + '.  ' + response.Authentication.HtmlResponse , 'warning');
                }
                else {
                    Alerts.ShowAlert('Deposit Status', response.Authentication.HtmlResponse, 'warning');
                }
                navigate('/deposits/');
            }

            else if (isMobile) {
                 Alerts.ShowAlertFullScreen('Deposit Status', response.Authentication.HtmlResponse + ' <p>TransactionID: ' + response.Authentication.TransactionID + ' </p>' + ' <p>Send ' + response.Authentication.Amount + '  to this ' + (cryptoNetwork == 'lightning' ? 'INVOICE' : 'address') + ':</p><p style="font-size: 0.9rem;font-weight: bold;">' + response.Authentication.Descriptor + '</p>', 'info', 'qrcode');
            }
            
        })
            .catch(function (error) {
                
                Alerts.ShowAlert('Error', error.response.data.Authentication.HtmlResponse, 'error');
            })

        
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { amount, cryptoNetwork, CurrencyCode } = Object.fromEntries(data.entries());
            if (amount !== '') {
                setIsProcessing(true);
                setOriginalAmount(amount);
                await doCryptoDeposit(amount, cryptoNetwork, CurrencyCode);
                setIsProcessing(false);
                setshowComponent(true);
                document.getElementById('amount').value = '';
            }
            else {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
            }

        }
    }
    if (isLoading)
        return (<Loading />);

    return (
        <div className="componentload">
            <form onSubmit={handleSubmit}>
                <div className="row m-4">
                    <span className="icon-single-left txt-primary">
                        <img style={{ width: "100px", height: "100px" }} src={"/svg/" + arrayRegularDeposits?.ImageName + ".svg"} /> Deposit using {arrayRegularDeposits?.DepositID}
                    </span>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="row">
                            {arrayCryptoNetworks.length > 0 && arrayCryptoNetworks[0] !== '' && <div className="col-md-12 mb-3">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-fa d-none d-md-block">
                                            <FontAwesomeIcon icon={faNetworkWired} />
                                        </span>
                                    </div>
                                    <select className="form-select border-teal selectpicker text-center" name="cryptoNetwork" onChange={(event) => { setCryptoNetwork(event.target.value); }}
                                        tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Select Network" required>
                                        {arrayCryptoNetworks.map((item, index) => <option key={index} value={item}>{item}</option>
                                        )}
                                    </select>
                                   
                                </div>
                            </div>}
                            <div className="col-md-12 m3">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-currency-cards d-none d-md-block" id="lblCurrency">
                                            <select className="form-control selectcurrency" disabled name="CurrencyCode" required value={clientInfo.CurrencyCode == 'mBTC' ? 'USD' : clientInfo.CurrencyCode}>
                                                <option value="" className="">Currency</option>
                                                <option selected value="USD">USD</option>
                                                <option value="EUR">EUR</option>
                                                <option value="GBP">GBP</option>
                                                <option value="CAD">CAD</option>
                                            </select>
                                        </span>
                                    </div>
                                    <input type="number" onKeyPress={(event) => onlyNumbers(event)} aria-label="Amount (to the nearest dollar)" className="form-control" id="amount" aria-describedby="lblCurrency" placeholder="Amount" max={arrayRegularDeposits?.MaxDeposit === 0 ? MAXLIMIT : arrayRegularDeposits?.MaxDeposit} min={arrayRegularDeposits?.MinDeposit === 0 ? 1 : arrayRegularDeposits?.MinDeposit} name="amount" required=""/>
                                </div>

                                <div className="d-flex flex-row ml-2 limit-text-center mb-4 ">
                                    Deposit Limits: Minimum {arrayRegularDeposits?.MinDeposit === 0 ? 1 : arrayRegularDeposits?.MinDeposit} USD Maximum {arrayRegularDeposits?.MaxDeposit === 0 ? 'No Limit' : arrayRegularDeposits?.MaxDeposit + ' USD'}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {showComponent && <div id="qrcode" name="qrcode" className="col-md-6 col-sm-12 componentload">
                        <div className="row">
                            <div className="col-md-4 mb-3" dangerouslySetInnerHTML={{ __html: CryptoResponse.HtmlResponse }}>
                                
                            </div>
                            <div className="col-md-8 mb-3">
                                TransactionID: <b className="crypto-address">{CryptoResponse.TransactionID}</b>
                                <br />
                                Send<span className="crypto-address">&nbsp;&nbsp; {CryptoResponse.Amount === null ? OriginalAmount + ' USD' : CryptoResponse.Amount}</span>&nbsp;&nbsp;<FontAwesomeIcon icon={faCopy} onClick={() => { navigator.clipboard.writeText(CryptoResponse.Amount === null ? OriginalAmount + ' USD' : CryptoResponse.Amount) }} style={{ cursor: 'pointer' }} />  to this {cryptoNetwork == 'lightning' ? 'INVOICE' : 'address'}:
                                <br /><br />
                                <span className="crypto-address text-center">{CryptoResponse.Descriptor} &nbsp;<FontAwesomeIcon icon={faCopy} onClick={() => { navigator.clipboard.writeText(CryptoResponse.Descriptor) }} style={{ cursor: 'pointer' }} /></span>
                                </div>
                        </div>
                    </div>}
                </div>
                {arrayCryptoNetworks.length > 0 && arrayCryptoNetworks[0] !== '' && <div className="row">
                    <div className="col-12">
                        <div className="alert alert-danger text-center" role="alert">
                            Only {cryptoNetwork} can be sent to the generated address from the selected network.  Deposits of other crypto currencies or networks will be lost.
                        </div>
                    </div>
                </div>}
                <Bonus ProcessorName={arrayRegularDeposits?.ImageName}/>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 " >
                    
                    </div>
                    <div className="col-md-12 mb-3-small mt-4 actionbuttons">
                        <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4" onClick={() => navigate('/deposits/')}>BACK</button>
                        <button type="submit" className="btn btn-deposit btn-deposit-order mb-4 submit">DEPOSIT</button>
                    </div>
                </div>  


            </form>
            <div>
                {isProcessing && <Loading />}
            </div>
        </div>
    );
}