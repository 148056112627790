import { useEffect, useState } from 'react';
import clientInformation from '../login/ClientInfo';
import Alerts from '../helpers/Alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { IsValidToken, GetCustomerInfo, GetCountries, GetStatesbyCountry, UpdateCustomerBillingInfo, CheckCustomerData } from "../../services/AuthenticationService";
import { GetCardAddress, Is3dTransaction, CreditCardDeposit } from "../../services/DepositService";
import { BonusString, onlyNumbers, cc_format, luhnChk, stringDate, isDate, validateExp } from '../helpers/common';
import { useParams, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import BeatLoader from "react-spinners/BeatLoader"
import { Button, Collapse } from 'react-bootstrap'
import Bonus from '../Bonus';
import Loading from '../helpers/Loader'
import { MissingAccountInfo } from "../deposit/MissingAccountInfo.jsx";

export const CreditCardMethods = () => {
    const clientInfo = clientInformation();
    const { id } = useParams();
    const [checkedBonus, setCheckedBonus] = useState([]);
    const [arrayRegularDeposits, setRegularDeposits] = useState(JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID == id; })[0]);
    const [arrayCountries, setArrayCountries] = useState([]);
    const [arrayStates, setArrayStates] = useState([]);
    const [open, setOpen] = useState(true);
    const [creditcardNumber, setCreditcardNumber] = useState('');
    const [creditcardBrand, setCreditcardBrand] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [chkBillingAddress, setBillingAddress] = useState(false);
    const [stateCode, setStateCode] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const navigate = useNavigate();
    let INSTANCEID = clientInfo.InstanceID;
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    let ReturnURL = process.env.REACT_APP_ReturnURL;
    let StatusURL = process.env.REACT_APP_StatusURL;
    let APIMobileWebServiceURL = process.env.REACT_APP_BASEURL;
    const [isProcessing, setIsProcessing] = useState(false);
    const [isValidate, setIsValidate] = useState(true);


    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            doCheckCustomerData();
            await GetCountries(localStorage.getItem('Token')).then(async function (response) {                
                var i = 0;
                const filtered = response.Authentication.map(item => {
                    const {
                        Code,
                        Name } = item;

                return { Key:i++,Code, Name}
                });
                setArrayCountries(filtered);
                setCountryCode('US');
                handleStatebyCountry('US', true);
            });
        })
            .catch(function (error) {
                console.log('CC' + error);
                navigate('/expired/');
            })
    }, [])

    function handleResponseFromComponent(response) {
        setIsValidate(response);
    }

    async function doCheckCustomerData() {
        await CheckCustomerData(localStorage.getItem('Token'), 'creditcard').then(async function (response) {
            if (!response.Authentication.AllComplete) {
                setIsValidate(true);
            }
            else {
                setIsValidate(false);
            }
        })
    };
    
    const handleStatebyCountry = async (selectedCountryCode, setState) => {
        await GetStatesbyCountry(selectedCountryCode).then(async function (response) {
            var i = 0;
            const filtered = response.Authentication.map(item => {
                const {
                    Code,
                    Name } = item;

                return { Key: i++, Code, Name }
            });
            setArrayStates(filtered);
            if (setState) {
                if (filtered.length > 1)
                    setStateCode(filtered[1].Code);
                else
                    setStateCode(filtered[0].Code);
            }
        })
    }
    
    const handleCheckBillingAddress = async (ischecked)=> {
        await GetCustomerInfo(localStorage.getItem('Token')).then(async function (response) {
            if (ischecked) {
                setCountryCode(response.Authentication.CountryCode);
                handleStatebyCountry(response.Authentication.CountryCode,false);
                setAddress(response.Authentication.Address);
                setCity(response.Authentication.City);
                setZipCode(response.Authentication.ZipCode);
                setStateCode(response.Authentication.StateCode);
                
            }
        })
    }

    const handleCreditCardonChange = (e) => {
        setCreditcardNumber(e.target.value);
        setCreditCardType(e.target.value);
    };

    const handleCreditCardBlur = async (event) => {
        var _ccNumber = event.target.value;
        setCreditCardType(_ccNumber);
        var _last4 = '';
        if (_ccNumber != undefined && _ccNumber.length > 4) {
            _last4 = _ccNumber.substring(_ccNumber.length - 4);
        }

        await GetCardAddress(localStorage.getItem('Token'), _last4).then(async function (response) {
            setCountryCode(response.Authentication.CountryCode);
            handleStatebyCountry(response.Authentication.CountryCode, false);
            setAddress(response.Authentication.Address);
            setCity(response.Authentication.City);
            setZipCode(response.Authentication.ZipCode);
            setStateCode(response.Authentication.StateCode);
        }).
            catch({})
    }

    function GetCCType(cardBrand) {
        return JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID == cardBrand; })[0].CreditCardTypeID;
    }

    function setCreditCardType(no) {
        setCreditcardBrand('');

        if (!no) { setCreditcardBrand(id); return; }

        var firstnum = no.toString().substring(0, 1);
        
        switch (firstnum) {
            case "4": setCreditcardBrand('VISA'); break;//Visa
            case "5": setCreditcardBrand('MASTERCARD'); break;//MC
            case "3":
                {
                    switch (no.toString().substring(0, 2)) {
                        case "34": setCreditcardBrand('AMERICANEXPRESS'); break;//AMERICANEXPRESS
                        case "37": setCreditcardBrand('AMERICANEXPRESS'); break;//AMERICANEXPRESS
                        default: setCreditcardBrand('DinersClub'); //DinerClub
                    }
                    break;
                }
            case "6":
                {
                    switch (no.toString().substring(0, 2)) {
                        case "67": setCreditcardBrand('Maestro'); break;
                        case "64": setCreditcardBrand('Discover'); break;
                        case "60": setCreditcardBrand('Discover'); break;
                    }
                    break;
                }

            default: setCreditcardBrand(id);
                break; //Invalid
        }
    }

    function submit3DMethod(json, webAPI) {
        var userData = encodeURIComponent(json);
        var win = window.open(webAPI + "AsyncOperation/Sale/" + "?JsonData=" + userData, "_self", "location=yes");
        //win.addEventListener('exit', function (event) { $scope.showAlert("Your transaction was Processed"); $scope.clear(); });
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { amount, ccHolder, creditcardnumber, cvvnumber, ExpYY, ExpMM, ccCurrency } = Object.fromEntries(data.entries());
            if (amount == '') {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
                return;
            }
            if (!luhnChk(creditcardnumber.replace(/\s/g, ''))) {
                Alerts.ShowAlert('Warning', 'Invalid Creditcard number', 'warning');
                return;
            }
            if (JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID == creditcardBrand; }) == undefined) {
                Alerts.ShowAlert('Warning', 'You need to enter a credit card number from a brand that is supported.', 'warning');
                return;
            }
            if (cvvnumber == undefined || cvvnumber.length < 3) {
                Alerts.ShowAlert('Warning', 'You need to enter a valid cvv number.', 'warning');
                return;
            }
            else {
                if (creditcardBrand === 'AMERICANEXPRESS' && cvvnumber.length < 4) {
                    Alerts.ShowAlert('Warning', 'You need to enter a valid cvv number.', 'warning');
                    return;
                }
                else if (creditcardBrand !== 'AMERICANEXPRESS' && cvvnumber.length > 3) {
                    Alerts.ShowAlert('Warning', 'You need to enter a valid cvv number.', 'warning');
                    return;
                }
            }

            var ExpYear = '20' + ExpYY;
            var ExpDateString = stringDate(ExpMM, "1", ExpYear);
            if (isDate(ExpDateString)) {
                if (!validateExp(ExpDateString)) {
                    Alerts.ShowAlert('Warning', 'Credit Card Expiration Date should be at least on this month.<br/> Date format is <b>MM/YY</b>', 'warning');
                    return;
                }
            }
            else {
                Alerts.ShowAlert('Warning', 'Invalid expiration date', 'warning');
                return;
            }
            setIsProcessing(true);
            //Transaction data
            var ccdata = {
                "Token": localStorage.getItem('Token'),
                "ProcessorName": '',
                "TransactionID": '-1',
                "CreditCardHolder": ccHolder,
                "CreditCardNumber": creditcardnumber.replace(/\s/g, ''),
                "CreditCardExpirationMonth": ExpMM,
                "CreditCardExpirationYear": ExpYear,
                "CreditCardType": GetCCType(creditcardBrand),
                "CreditCardCVV": cvvnumber,
                "Amount": amount,
                "CurrencyCode": clientInfo.CurrencyCode,
                "IPv4Address": clientInfo.IPAddress,
                "Comments": '',
                "CSID": '',
                "InstanceID": INSTANCEID,
                "OverrideLimit": 'false',
                "Source": 'Mobile',
                "User": 'API',
                "DepositSource": '',
                "BlackBox": '',
                "ReturnURL": ReturnURL,
                "StatusURL": StatusURL,
                "BonusList": BonusString(JSON.parse(localStorage.getItem('ArrayBonus'))),
                "Udf1": clientInfo.Udf1
            };
            
            var updateAddress = {
                "Token": localStorage.getItem('Token'),
                "Address": address,
                "City": city,
                "ZipCode": zipCode,
                "CountryCode": countryCode,
                "StateCode": stateCode,
                "CreditCardNumber": creditcardnumber.replace(/\s/g, ''),
                "CreditCardCVV": cvvnumber,
                "CreditCardExpirationMonth": ExpMM,
                "CreditCardExpirationYear": ExpYear,
                "CreditCardHolder": ccHolder
            };
            //Update customer billing Info
            await UpdateCustomerBillingInfo(updateAddress);

            await Is3dTransaction(ccdata).then(async function (response) {
                if (response != false) {
                    // 3D secure processing
                    var json = JSON.stringify(ccdata);
                    submit3DMethod(json, APIMobileWebServiceURL);
                }
                else {
                    await CreditCardDeposit(ccdata).then(async function (response) {
                        setCreditcardBrand(id);
                        setAddress('');
                        setCity('');
                        setZipCode('');
                        setCountryCode('US');
                        handleStatebyCountry('US', true);
                        
                        setIsProcessing(false);

                        if (response.Authentication.Notes == undefined || response.Authentication.Notes != 'Hide') {
                            if (isMobile)
                                Alerts.ShowAlertFullScreen('Credit Card Deposit Status', response.Authentication.HtmlResponse, 'info');
                            else
                                Alerts.ShowAlert('Credit Card Deposit Status', response.Authentication.HtmlResponse, 'info');
                        }

                        //Clean inputs
                        document.getElementById('amount').value = '';
                        document.getElementById('focus1').value = '';
                        document.getElementById('focus2').value = '';
                        document.getElementById('focus3').value = '';
                        document.getElementById('focus4').value = '';
                        document.getElementById('amount').style.backgroundColor = null;
                        document.getElementById('focus1').style.backgroundColor = null;
                        document.getElementById('focus2').style.backgroundColor = null;
                        document.getElementById('focus3').style.backgroundColor = null;
                        document.getElementById('focus4').style.backgroundColor = null;

                    }).catch(function (error) {
                        Alerts.ShowAlert('Error with Creditcard deposit', error.response.data.Authentication.HtmlResponse, 'error');
                    })
                }

            }).catch(function (error) {
                Alerts.ShowAlert('Error', error.response.data.Authentication.HtmlResponse, 'error');
            })

            setIsProcessing(false);
        }
        //event.target.reset();
    }

    const validateRange = async (event, name) => {
        let { value, min, max } = event.target;
        if ((min === '' || parseInt(value) >= parseInt(min)) && (max === '' || parseInt(value) <= parseInt(max) )) {
            document.getElementsByName(name)[0].style.backgroundColor = 'lightgreen';
        }
        else {
            document.getElementsByName(name)[0].style.backgroundColor = '#E55451';
        }
    }

    if (isValidate) {
        return <MissingAccountInfo Processor={'creditcard'} isValidate={handleResponseFromComponent.bind(isValidate)} />;
    }

    return (
        <div className="componentload">
            <form onSubmit={handleSubmit}>
            <div className="row m-3">
                <span className="icon-single-left txt-primary">
                    <img style={{ width: "100px", height: "100px" }} src={"/svg/" + arrayRegularDeposits.ImageName + ".svg"} /> Deposit using Credit Card
                </span>
            </div>
            <div className="row m-2">
                <div className="col-md-6 mb-3">
                    <input id="focus" type="text" className="form-control border-teal" name="ccHolder" aria-label="credit card holder" placeholder="Full name on card" required="required" />
                </div>
                
                <div className="col-md-6 col-sm-12 col-xs-12 mb-3" >
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-currency-cards d-none d-md-block" id="lblCurrency">
                                <select className="form-control selectcurrency" disabled name="ccCurrency" required value={clientInfo.CurrencyCode}>
                                    <option value="" className="">Currency</option>
                                    <option value="USD">USD</option>
                                    <option value="EUR">EUR</option>
                                    <option value="GBP">GBP</option>
                                    <option value="CAD">CAD</option>
                                </select>
                            </span>
                        </div>
                            <input type="number" onKeyPress={(event) => onlyNumbers(event)} className="form-control color-gray border-teal" aria-label="Amount (to the nearest dollar)" placeholder="Amount" name="amount" id="amount" max={arrayRegularDeposits?.MaxDeposit == 0 ? MAXLIMIT : arrayRegularDeposits?.MaxDeposit} min={arrayRegularDeposits?.MinDeposit == 0 ? 1 : arrayRegularDeposits?.MinDeposit} aria-describedby="lblCurrency" required="required" onBlur={(event) => validateRange(event, 'amount')} />
                    </div>
                    <div className="d-flex flex-row ml-2 limit-text-center ">
                        Deposit Limits: Minimum  {arrayRegularDeposits?.MinDeposit == 0 ? 1 : arrayRegularDeposits?.MinDeposit} USD Maximum {arrayRegularDeposits?.MaxDeposit == 0 ? 'No Limit' : arrayRegularDeposits?.MaxDeposit + ' USD'}
                    </div>
                </div>
                <div className="col-md-6 mb-4">
                    <input type="tel" className="form-control border-teal creditcardnumber" name="creditcardnumber" id="focus1" aria-label="credit card number" placeholder="Credit Card Number"
                        minLength="17" maxLength="19" autoComplete="off" required onBlur={(event) => handleCreditCardBlur(event)} onKeyPress={(event) => onlyNumbers(event)}
                        value={cc_format(creditcardNumber)} onChange={(event) =>handleCreditCardonChange(event)}/>
                </div>
                <div className="col-md-3 mb-4">
                    
                        <div className="row">
                            <div className="col-6">
                                <input type="tel" className="form-control border-teal " name="ExpMM" id="focus2" move-next-on-maxlength="" aria-label="Exp" placeholder="MM" maxLength="2" min="1" max="12" required="required" onKeyPress={(event) => onlyNumbers(event)} onBlur={(event) => validateRange(event,'ExpMM')}/>
                            </div>
                            <div className="col-6">
                                <input type="tel" className="form-control border-teal" name="ExpYY" id="focus3" move-next-on-maxlength="" aria-label="DOB" placeholder="YY" maxLength="2" min={new Date().getFullYear().toString().substr(-2)} required="required" onKeyPress={(event) => onlyNumbers(event)} onBlur={(event) => validateRange(event, 'ExpYY')}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                    
                        <input type="tel" className="form-control border-teal" name="cvvnumber" aria-label="cvv" placeholder="cvv" id="focus4" maxLength="4" required="required" onKeyPress={(event) => onlyNumbers(event)} />
                    </div>
                    <div className="row" >
                        <div className="col-md-9">
                            <Button
                                variant="link"
                                onClick={() => setOpen(!open)}
                                aria-expanded={open}
                                aria-controls="collapseID"
                            >
                                Billing Address <FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2"/>
                        <div className="col-md-9">
                            <Collapse in={open}>
                                <div className="row">
                                    <div className="col-12">
                                
                                        <label>
                                            <input type="checkbox" style={{ float: 'left', marginTop: '5px' }} value={chkBillingAddress} onChange={(event) => { setBillingAddress(event.target.checked); handleCheckBillingAddress(event.target.checked); }} name="chkBillingAddress"  />
                                            &nbsp;&nbsp;Tick this check box If your residential address is the same as billing address.
                                        </label>
                                
                                </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12 mb-3">
                                        <input type="text" className="form-control border-teal" name="address" value={address} onChange={(event) => { setAddress(event.target.value); }}
                                        tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Enter the Address"
                                        aria-label="Address" placeholder="Address" required />
                                </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 mb-3">
                                        <select className="form-select border-teal" value={countryCode} onChange={(event) => { setCountryCode(event.target.value); handleStatebyCountry(event.target.value, true) }}
                                            tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Select the Country" required>
                                            {arrayCountries.map((item) => <option key={item.Key} value={item.Code}>{item.Name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 mb-3">
                                        <select className="form-select border-teal" value={stateCode} onChange={(event) => setStateCode(event.target.value) }
                                            tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Select the State" required>
                                            {arrayStates.map((item) => <option key={item.Key} value={item.Code}>{item.Name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-6 col-sm-3 col-xs-12 mb-3">
                                        <input type="text" className="form-control border-teal" name="city" value={city} onChange={(event) => { setCity(event.target.value); }}
                                        tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Enter the City"
                                        aria-label="City" placeholder="City" required />
                                    </div>
                                    <div className="col-md-6 col-sm-3 col-xs-12 mb-3">
                                        <input type="text" className="form-control border-teal" name="zipcode" value={zipCode} onChange={(event) => { setZipCode(event.target.value); }}
                                        tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Enter the Zip Code"
                                        aria-label="Zip Code" placeholder="Zip Code" required />
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    <Bonus  ProcessorName="Creditcard"/>
                    <div className="row">
                        <div className="col-12 actionbuttons">
                            <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4 " onClick={() => navigate('/deposits/')}>BACK</button>
                            <button type="submit" className="btn btn-deposit btn-deposit-order mb-4 submit">DEPOSIT</button>
                        </div>
                    </div>
                    </div>
            </form>
            <div>
                {isProcessing && <Loading/>}
            </div>
        </div>
    );
}