import { Footer } from "../components/Footer"
import { PayoutDetails } from "../components/payout/PayoutDetails"


export const PayoutHistoryPage = () => {
    return (
        <div className="container">
            <PayoutDetails />
            <Footer />
        </div>
    );
}