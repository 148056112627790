import { Footer } from "../components/Footer"
import { PayoutGenericMethods } from "../components/payout/PayoutGenericMethods"


export const PayoutGenericPage = () => {
    return (
        <div className="container">
            <PayoutGenericMethods />
            <Footer />
        </div>
    );
}