import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import clientInformation from '../login/ClientInfo';
import Alerts from '../helpers/Alerts';
import { IsValidToken, CheckCustomerData, UpdateCustomerInfo, GetCountries, GetStatesbyCountry, GetCurrencies, GetCustomerInfo } from "../../services/AuthenticationService";
import Loading from '../helpers/Loader'
import { stringDate, checkDOB } from '../helpers/common'

export const MissingAccountInfo = ({ Processor = null, isValidate = true }) => {
    const clientInfo = clientInformation();
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();
    const [customerData, setCustomerData] = useState([]);
    const [arrayCountries, setArrayCountries] = useState([]);
    const [arrayStates, setArrayStates] = useState([]);
    const [arrayCurrencies, setArrayCurrencies] = useState([]);
    const [stateCode, setStateCode] = useState('');
    const [countryCode, setCountryCode] = useState('US');
    const [currencyCode, setCurrencyCode] = useState('USD');

    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            await doCheckCustomerData();
            setIsLoading(false);
        }

        ).catch(function (error) {
            setIsLoading(false);
            navigate('/expired/');
        })

    }, []);

    async function doCheckCustomerData() {
        await CheckCustomerData(localStorage.getItem('Token'), Processor).then(async function (response) {
            if (response.Authentication.ErrorDescription != undefined && response.Authentication.ErrorDescription != null
                && response.Authentication.ErrorDescription != '') {
            }
            else {
                await setCustomerData(response.Authentication);

                if (response.Authentication.AllComplete) {
                    if (Processor == null) {
                        navigate('/main');
                    }
                    else {
                        isValidate(false);
                    }
                    
                }

                await GetCountries(localStorage.getItem('Token')).then(async function (response) {
                    var i = 0;
                    const filtered = response.Authentication.map(item => {
                        const {
                            Code,
                            Name } = item;

                        return { Key: i++, Code, Name }
                    });
                    setArrayCountries(filtered);
                    //setCountryCode('US');
                    //handleStatebyCountry('US', true);
                    handleCountryState();
                });

                await GetCurrencies().then(async function (response) {
                    setArrayCurrencies(response.Authentication);
                });
            }
        })
    };
    const handleCountryState = async () => {
        await GetCustomerInfo(localStorage.getItem('Token')).then(async function (response) {
            setCountryCode(response.Authentication.CountryCode);
            handleStatebyCountry(response.Authentication.CountryCode, false);
            setStateCode(response.Authentication.StateCode);
        });
    }

    const handleStatebyCountry = async (selectedCountryCode, setState) => {
        await GetStatesbyCountry(selectedCountryCode).then(async function (response) {
            var i = 0;
            const filtered = response.Authentication.map(item => {
                const {
                    Code,
                    Name } = item;

                return { Key: i++, Code, Name }
            });
            setArrayStates(filtered);
            if (setState) {
                if (filtered.length > 1)
                    setStateCode(filtered[1].Code);
                else
                    setStateCode(filtered[0].Code);
            }
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            setIsProcessing(true);
            var data = new FormData(event.target);
            let { name, lastname, secondlastname, phone, email, city, address, zipcode, dobMM, dobDD, dobYY } = Object.fromEntries(data.entries());

            var infoDOB = stringDate(dobMM, dobDD, dobYY);
            var res = checkDOB(customerData.MissingDOB, infoDOB);
            if (res != null) {
                Alerts.ShowAlert('Warning', 'Invalid Date of Birth.' + res, 'warning');
                setIsProcessing(false);
                return;
            }

            var formData = {
                "Token": localStorage.getItem('Token'),
                "Name": name,
                "LastName": lastname,
                "SecondLastName": secondlastname,
                "SSN": '1111',
                "Email": email,
                "Birthdate": infoDOB,
                "Phone": phone,
                "Address": address,
                "Address2": '',
                "City": city,
                "ZipCode": zipcode,
                "CountryCode": countryCode,
                "StateCode": stateCode,
                "CurrencyCode": currencyCode,
                "CustomerProfileID": 0
            };

            await UpdateCustomerInfo(formData).then(async function (response) {
                if (!response.Authentication.Successful) {
                    if (response.Authentication.ErrorDescription == 'validation') {
                        navigate('/validation/');
                    }
                    else {
                        setIsProcessing(false);
                        Alerts.ShowAlert('Error', response.Authentication.ErrorDescription, 'error');
                    }
                }
                else {
                    if (customerData.MissingCurrency == true) {
                        localStorage.setItem('CurrencyCode', currencyCode);
                        clientInfo.CurrencyCode = currencyCode;
                    }
                    if (customerData.MissingName == true) {
                        localStorage.setItem('Name', name);
                        clientInfo.Name = name;
                    }
                    if (customerData.MissingLastName == true) {
                        localStorage.setItem('LastName', lastname);
                        clientInfo.LastName = lastname;
                    }
                    if (Processor == null) {
                        navigate('/main');
                    }
                    else {
                        isValidate(false);
                    }
                    setIsProcessing(false);
                }

            });
        }
        else {
            setIsProcessing(false);
            Alerts.ShowAlert('Warning', 'Please fill all required values', 'warning');
        }
    }
    

    if (isLoading) {
        return <Loading />;
    }

    return (<div className="componentload">
        <div class="title">
            <div class="container">
                <h1 class="txt-primary">Missing Account Info</h1>
            </div>
        </div>
        <div class="container">
            <form class="form-inline" name="personalinfoForm" onSubmit={handleSubmit}>
            {customerData.MissingName && <div class="row mb-3">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label for="firstname" class="col-md-2  col-sm-2 col-xs-12">FirstName:</label>
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <input type="text" class="form-control" name="name" id="name"
                                aria-label="Name" placeholder="FirstName" required />
                    </div>
                </div>
            </div>}

            {customerData.MissingLastName && <div class="row mb-3" >
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label for="firstname" class="col-md-2  col-sm-2 col-xs-12">LastName:</label>
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <input type="text" class="form-control" name="lastname" 
                                aria-label="Last Name" placeholder="LastName" required />
                    </div>
                </div>
            </div>}

            {customerData.MissingSecondLastName && <div class="row mb-3">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label for="firstname" class="col-md-2  col-sm-2 col-xs-12">SecondLastName:</label>
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <input type="text" class="form-control" name="secondlastname" 
                                aria-label="Second Last Name" placeholder="SecondLastName" required />
                    </div>
                </div>
            </div>}

            {customerData.MissingPhone && <div class="row mb-3">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label for="firstname" class="col-md-2  col-sm-2 col-xs-12">Phone:</label>
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <input type="number" class="form-control" name="phone"
                                aria-label="Phone" placeholder="Phone" required />
                    </div>
                </div>
            </div>}

            {customerData.MissingEmail && <div class="row mb-3" >
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label for="firstname" class="col-md-2  col-sm-2 col-xs-12">EmailAddress:</label>
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <input type="text" class="form-control" name="email" 
                                aria-label="Email" placeholder="EmailAddress" required />
                    </div>
                </div>
            </div>}

            {customerData.MissingCity && <div class="row mb-3" >
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label for="firstname" class="col-md-2  col-sm-2 col-xs-12">City:</label>
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <input type="text" class="form-control" name="city"
                                aria-label="City" placeholder="City" required />
                    </div>
                </div>
            </div>}

            {customerData.MissingAddress1 && <div class="row mb-3">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label for="firstname" class="col-md-2  col-sm-2 col-xs-12">Address:</label>
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <input type="text" class="form-control" name="address"
                                aria-label="Address" placeholder="Address" required />
                    </div>
                </div>
            </div>}

            {customerData.MissingZipCode && <div class="row mb-3">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label for="firstname" class="col-md-2  col-sm-2 col-xs-12">ZipCode:</label>
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <input type="text" class="form-control" name="zipcode" 
                                aria-label="Zip Code" placeholder="ZipCode" required />
                    </div>
                </div>
            </div>}

            {customerData.MissingDOB && <div class="row mb-3" >
                    <div class="col-12"><label for="firstname" class="col-md-3  col-sm-3 col-xs-12">Birthdate:</label></div>
                    
                    <div class="col-md-2 col-sm-4 col-xs-12">
                        <input type="number" class="form-control" name="dobMM"  
                            id="DOBelement" onkeypress="return onlyNumbers(event)" onkeyup="validateEnteredDate(this, 1, 12)"
                            aria-label="Birth Date" placeholder="MM" maxlength="2" required />
                    </div>
                    <div class="col-md-2 col-sm-4 col-xs-12">
                        <input type="number" class="form-control" name="dobDD" 
                            id="DOBelement" onkeypress="return onlyNumbers(event)" onkeyup="validateEnteredDate(this, 1, 31)"
                            aria-label="Birth Date" placeholder="DD" maxlength="2" required />
                    </div>
                    <div class="col-md-2 col-sm-4 col-xs-12">
                        <input type="number" class="form-control" name="dobYY" 
                            id="DOBelement" onkeypress="return onlyNumbers(event)" onkeyup="validateEnteredDate(this, 1, 2050)"
                                aria-label="Birth Date" placeholder="YYYY" maxlength="4" required />
                    </div>
            </div>}

            {customerData.MissingCountry && <div class="row mb-3" >
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label for="Country" class="col-md-2  col-sm-2 col-xs-12">Country:</label>
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <select className="form-select border-teal" value={countryCode} onChange={(event) => { setCountryCode(event.target.value); handleStatebyCountry(event.target.value, true) }}
                                tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Select the Country" required>
                            {arrayCountries.map((item) => <option key={item.Key} value={item.Code}>{item.Name}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>}

            {customerData.MissingState && <div class="row mb-3" >
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label for="state" class="col-md-2  col-sm-2 col-xs-12">State:</label>
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <select className="form-select border-teal" value={stateCode} onChange={(event) => setStateCode(event.target.value)}
                                tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Select the State" required>
                            {arrayStates.map((item) => <option key={item.Key} value={item.Code}>{item.Name}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>}

            {customerData.MissingCurrency && <div class="row mb-3">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label for="firstname" class="col-md-2  col-sm-2 col-xs-12">Currency:</label>
                    <div class="col-md-10 col-sm-10 col-xs-12">
                        <select className="form-select border-teal" value={currencyCode} onChange={(event) => setCurrencyCode(event.target.value)}
                                tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Select the State" required>
                            {arrayCurrencies.map((item) => <option key={item.Key} value={item.CurrencyCode}>{item.Name}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>}

            <div class="row mt-4">
                <div class="col-md-4 col-sm-12 col-xs-12">
                        <button type="submit" class="btn btn-secondary col-md-12 col-sm-12 col-xs-12">Submit</button>
                </div>
            </div>


            </form>
        </div>
        <div>
            {isProcessing && <Loading />}
        </div>
    </div>);

}