import { Footer } from "../components/Footer"
import { PayoutCheckMethods } from "../components/payout/PayoutCheckMethods"


export const PayoutCheckPage = () => {
    return (
        <div className="container">
            <PayoutCheckMethods />
            <Footer />
        </div>
    );
}