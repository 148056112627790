import { Footer } from "../components/Footer"
import { PriorityMethods } from "../components/deposit/PriorityMethods"
import { ZelleMethods } from "../components/deposit/ZelleMethods"


export const DepositsZellePage = () => {
    return (
        <div className="container">
            <PriorityMethods />
            <ZelleMethods />
            <Footer />
        </div>
    );
}