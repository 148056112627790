import { Footer } from "../components/Footer"
import { CustomerInfoHeader } from "../components/payout/CustomerInfoHeader"
import { PayoutMethods } from "../components/payout/PayoutMethods"

export const PayoutsPage = () => {
    return (
        <div className="container">
            <CustomerInfoHeader />
            <PayoutMethods />
            <Footer />
        </div>
    );
}