import { Footer } from "../components/Footer"
import { PriorityMethods } from "../components/deposit/PriorityMethods"
import { EwalletMethods } from "../components/deposit/EwalletMethods"


export const DepositEwalletPage = () => {
    return (
        <div className="container">
            <PriorityMethods />
            <EwalletMethods />
            <Footer />
        </div>
    );
}