import { Footer } from "../components/Footer"
import { PayoutCryptoMethods } from "../components/payout/PayoutCryptoMethods"


export const PayoutCryptoPage = () => {
    return (
        <div className="container">
            <PayoutCryptoMethods />
            <Footer />
        </div>
    );
}