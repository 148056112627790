import Axios from "axios"

var BASEURL = process.env.REACT_APP_BASEURL

export const GetPayoutsLimits = (Token, Currency, Amount) => {

    var TokenData = {
        "Token": Token,
        "Currency": Currency,
        "Amount": "-1"
    };

    return Axios.post(BASEURL + "api/Payouts/GetPayoutLimits", TokenData).then((res) => res.data);
}

export const RequestEWalletPayout = (formData) => {
  
    return Axios.post(BASEURL + "api/payouts/RequestEWalletPayout", formData).then((res) => res.data);
}

export const RequestECheckPayout = (formData) => {

    return Axios.post(BASEURL + "api/payouts/RequestEcheckPayout", formData).then((res) => res.data);
}

export const GetGenericPayoutFormProperties = (formData) => {

    return Axios.post(BASEURL + "api/Payouts/GetPayoutGenericForm", formData).then((res) => res.data);
}

export const GenericPayout = (formData) => {

    return Axios.post(BASEURL + "api/Payouts/GenericPayout", formData).then((res) => res.data);
}

export const CancelPayout = (Token, TransactionID) => {
    var formData = {
        "Token": Token,
        "TransactionID": TransactionID
    };
    return Axios.post(BASEURL + "api/Payouts/CancelPayout", formData).then((res) => res.data);
}

export const PendingPayouts = (formData) => {

    return Axios.post(BASEURL + "api/Payouts/PendingPayouts", formData).then((res) => res.data);
}
