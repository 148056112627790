import React from "react";
import Alerts from '../components/helpers/Alerts';

export const ExpiredPage = () => {
    Alerts.SessionAlert('Warning', process.env.REACT_APP_SessionExpired + " please log back again into the cashier.", 'warning');

    return (
        <div>
            <div className="limiter">
                <div className="container-login100 ">
                    &nbsp;
                </div>
            </div>
        </div>
    );
}